<template>
  <div class="one-column-width">



    <!-- form start -->
    <v-card class="add-form-title" v-if="showFormData">
      <v-card-title> Application Summary </v-card-title>
     
            
            <div class="col-md-11  m-5" style="border: 1px solid">
              <div class="col-md-12 row">
                <div class="col-md-3 pt-2 mt-5 form-group position-relative">
                  <label>Email <span class="text-danger">*</span></label>
                  <input :value="this.currentUser.email" class="form-control pl-5" disabled />
                </div>
                <div class="col-md-3 pt-2 mt-5 form-group position-relative" v-for="(item, index) in applicationValues" :key="index">
                  <label>{{item.label}} <span class="text-danger">*</span></label>
                  <input :value="item.value" class="form-control pl-5" disabled />
                </div>
                
              </div>
            </div>
          
            <div class="mt-4 pt-2">
              
              <button
                type="button"
                class="btn btn-soft-danger ml-2"
                @click="cancelForm"
              >
                Cancel
              </button>
            </div>
            <br />
          
    </v-card>
    <!-- form end -->

    <!-- table start -->
    <v-card>
      <v-card-title>      
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search Your Applications"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search" loading>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showEditForm(item)"
          >
            <v-icon dark>mdi-eye</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="askUserAboutDelete(item)"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import AdmissionForm from "@/MainServices/AdmissionForm.js";
import { required, minLength } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      editId: null,
      deleteId: null,
      dialog: false,
      snackbar: false,
      snackBarText: null,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      applicationValues:null,
      headers: [
        {
          text: "Application Data",
          align: "left",
          sortable: false,
          value: "date",
          width: "15%",
        },
        { text: "University Name", align: "left", sortable: false, value: "university", width: "17%" },
        { text: "Course", align: "left", sortable: false, value: "course", width: "17%" },
        {
          text: "Form",
          align: "left",
          sortable: false,
          value: "form",
          width: "15%",
        },
        { 
          text: "Received",
          align: "left",
          sortable: false,
          value: "read_string",
          width: "10%",
        },
        { text: "status", align: "left", sortable: false, value: "status_string", width: "10%" },

        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
    };
  },
  components: {},

   computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    getInititalData() {
      AdmissionForm.getStudentApplications()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    showEditForm(item) {
      this.applicationValues = JSON.parse(item.values);
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.showFormData = false;
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      CampusService.deleteCampus(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Campus Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
  },
};
</script>
